module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NEAR Examples","short_name":"NEAR","start_url":"/","background_color":"#D1CCBD","theme_color":"#6AD1E3","display":"minimal-ui","icon":"src/images/near-icon-grey.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9e8bb7b07a64a1da22b53575aa47e478"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-100373569-19","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"df164f13212cbb0dfdae991da60e87f2","pageViews":"all","mixpanelConfig":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
